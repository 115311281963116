import React, { useState, useEffect } from "react"
import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHardDrive,
  faPen,
  faPlay,
  faSave,
} from "@fortawesome/free-solid-svg-icons"
import ExportModal from "components/UI/ExportModal/ExportModal"
import ExportDataModal from "./ExportDataModal.component"
import { Tooltip } from "antd"

/**
 * QueryConsoleActions component renders action buttons for the query console.
 *
 * This component includes buttons for viewing saved queries, running scripts, saving, and exporting scripts.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.toggleSaved - Function to toggle the view between saved queries and the editor.
 * @param {boolean} props.viewSavedQueries - Boolean indicating whether the saved queries view is active.
 * @param {Function} props.runScript - Function to run the script written in the editor.
 * @param {Function} props.saveProjectQuery - Function to save the project query.
 * @param {boolean} props.isRunningScript - Boolean indicating whether the script is currently running.
 * @param {String} props.queryInstance - The query instance ID.
 * @param {String} props.selectedQuery - The selected query.
 * @param {Function} props.setSelectedQuery - Function to set the selected query.
 * @param {Boolean} props.contentChanged - Boolean indicating whether the content has changed.
 * @param {String} props.activeKey - The active key of the tab.
 * @returns {JSX.Element} The rendered query console actions component.
 */

/**
 * Detects if the user is on a Mac device
 * @returns {boolean} True if the user is on a Mac, false otherwise
 */
const isMac = () => {
  return window.navigator.platform.toLowerCase().includes("mac")
}

const QueryConsoleActions = ({
  toggleSaved,
  viewSavedQueries,
  runScript,
  saveProjectQuery,
  queryInstance,
  isRunningScript,
  selectedQuery,
  setSelectedQuery,
  contentChanged,
  activeKey,
}) => {
  const [isSaveQueryModalVisible, setIsSaveQueryModalVisible] = useState(false)
  const isUnsaved = contentChanged && selectedQuery !== ""
  const saveShortcut = isMac() ? "⌘ + S" : "Ctrl + S"

  /**
   * Handles the confirmation of the query save action.
   *
   * @param {string} queryName - The name of the query to be saved.
   */
  const confirmSaveQuery = (queryName) => {
    saveProjectQuery(queryName)
    setSelectedQuery(queryName)
    setIsSaveQueryModalVisible(false)
  }

  /**
   * Handles the save button click.
   * If selectedQuery exists, saves directly with that name.
   * Otherwise shows the modal for entering a new name.
   */
  const handleSaveClick = () => {
    if (selectedQuery) {
      confirmSaveQuery(selectedQuery)
    } else {
      setIsSaveQueryModalVisible(true)
    }
  }

  /**
   * Sets up keyboard event listener for Ctrl+S save shortcut
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      const isReportingTab = String(activeKey) === "5"
      if (!isReportingTab) return

      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault() // Prevent browser's default save dialog
        handleSaveClick()
      }
    }

    // Add event listener
    document.addEventListener("keydown", handleKeyDown)

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [selectedQuery]) // Re-run effect if selectedQuery changes

  return (
    <>
      <ExportModal
        title="Save Query"
        isVisible={isSaveQueryModalVisible}
        confirm={confirmSaveQuery}
        cancel={setIsSaveQueryModalVisible}
        message="Please enter a filename for your query"
        ExportButtonText="Save"
      />
      {selectedQuery && (
        <div className="flex items-center text-sm mr-4">
          <div className="relative mr-2">
            <div
              className={`w-2 h-2 ${
                isUnsaved ? `bg-amber-400` : `bg-green-400`
              } rounded-full`}
            ></div>
            <div
              className={`absolute top-0 left-0 w-2 h-2 ${
                isUnsaved ? `bg-amber-400 animate-ping` : `bg-green-400`
              } rounded-full`}
            ></div>
          </div>

          <span
            className={`${
              isUnsaved ? `text-amber-600` : `text-green-400`
            } font-semibold`}
          >
            {isUnsaved ? `Unsaved changes` : "Saved"}
          </span>
        </div>
      )}
      <DataQButton onClick={() => toggleSaved()} type="primary">
        {!viewSavedQueries ? `View Saved Queries` : `View Editor`}
        {!viewSavedQueries ? (
          <FontAwesomeIcon icon={faHardDrive} className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faPen} className="ml-2" />
        )}
      </DataQButton>
      <DataQButton
        isDisabled={isRunningScript}
        onClick={() => runScript()}
        type="primary"
      >
        Run
        <FontAwesomeIcon icon={faPlay} className="ml-2" />
      </DataQButton>
      <Tooltip
        title={`Save Query (${saveShortcut})`}
        placement="top"
        overlayClassName="dataq-tooltip"
      >
        <span className="inline-block">
          <DataQButton onClick={handleSaveClick} type="primary">
            Save
            <FontAwesomeIcon icon={faSave} className="ml-2" />
          </DataQButton>
        </span>
      </Tooltip>
      <ExportDataModal queryInstance={queryInstance} />
    </>
  )
}

export default QueryConsoleActions
