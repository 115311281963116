import React, { useState, useEffect } from "react"
import { Modal, Input, Checkbox, Select } from "antd"
import DataQButton from "../Buttons/DataQButton"
import { ErrorNotification } from "../Notifications/NotificationTemplate.component"

/**
 * ExportModal Component
 *
 * @param {Object} props - The props object for ExportModal.
 * @param {string} props.title - The title of the modal.
 * @param {boolean} props.isVisible - Controls the visibility of the modal.
 * @param {function} props.cancel - Function to call when the cancel button is clicked.
 * @param {function} props.confirm - Function to call when the export button is clicked.
 * @param {string} [props.ExportButtonText='Export'] - The text displayed on the export button.
 * @param {string} [props.ExportButtonType='secondary'] - The type of the export button.
 * @param {string} props.message - The message displayed above the input field.
 * @param {Array} [props.additionalInputs] - Array of additional input configurations.
 * @param {boolean} [props.noFilename=false] - Whether to show the filename input.
 *
 * @returns {JSX.Element} The rendered ExportModal component.
 */
const ExportModal = (props) => {
  const {
    title,
    isVisible,
    cancel,
    confirm,
    ExportButtonText,
    ExportButtonType,
    message,
    additionalInputs = [],
    noFilename = false,
    defaultFilename = "",
  } = props

  // State for filename and additional inputs
  const [filename, setFilename] = useState(defaultFilename)
  const [additionalValues, setAdditionalValues] = useState(
    additionalInputs.reduce(
      (acc, input) => ({
        ...acc,
        [input.name]:
          input.type === "checkbox"
            ? false
            : input.type === "select"
            ? input.defaultValue || (input.multiple ? [] : undefined)
            : "",
      }),
      {}
    )
  )

  // Update filename when defaultFilename changes
  useEffect(() => {
    setFilename(defaultFilename)
  }, [defaultFilename])

  const handleFilenameChange = (e) => {
    setFilename(e.target.value)
  }

  const handleAdditionalInputChange = (name, value) => {
    setAdditionalValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const renderInput = (input) => {
    const isDisabled =
      typeof input.disabled === "function"
        ? input.disabled(additionalValues)
        : input.disabled

    switch (input.type) {
      case "checkbox":
        return (
          <Checkbox
            checked={additionalValues[input.name]}
            onChange={(e) =>
              handleAdditionalInputChange(input.name, e.target.checked)
            }
            disabled={isDisabled}
          >
            {input.checkboxLabel}
          </Checkbox>
        )

      case "select":
        return (
          <Select
            mode={input.multiple ? "multiple" : undefined}
            style={{ width: "100%" }}
            placeholder={input.placeholder}
            value={additionalValues[input.name]}
            onChange={(value) => handleAdditionalInputChange(input.name, value)}
            options={input.options}
            defaultValue={input.defaultValue}
            disabled={isDisabled}
          />
        )

      default:
        return (
          <Input
            value={additionalValues[input.name]}
            onChange={(e) =>
              handleAdditionalInputChange(input.name, e.target.value)
            }
            placeholder={input.placeholder}
            type={input.type || "text"}
            disabled={isDisabled}
          />
        )
    }
  }

  const handleConfirm = () => {
    if (!noFilename && !filename.trim()) {
      ErrorNotification("Error Exporting", "Please enter a filename")
      return
    }

    // Check if any required additional inputs are empty
    const emptyRequired = additionalInputs
      .filter((input) => input.required)
      .some((input) => {
        if (input.type === "select" && input.required) {
          return additionalValues[input.name].length === 0
        }
        if (input.type === "checkbox" && input.required) {
          return !additionalValues[input.name]
        }
        return !additionalValues[input.name]
      })

    if (emptyRequired) {
      ErrorNotification("Error Exporting", "Please fill in all required fields")
      return
    }

    if (noFilename) {
      confirm(additionalValues)
    } else {
      confirm(filename, additionalValues)
    }
  }

  return (
    <Modal
      centered
      title={title}
      open={isVisible}
      closable={false}
      footer={null}
    >
      <div className="flex flex-col mb-2">
        <p className="text-md">{message}</p>

        {/* Default filename input */}
        {!noFilename && (
          <div className="mb-2">
            <Input
              value={filename}
              onChange={handleFilenameChange}
              placeholder="Enter filename"
            />
          </div>
        )}

        {/* Additional inputs if any */}
        {additionalInputs.map((input) => (
          <div key={input.name} className="mb-2">
            {input.type !== "checkbox" && (
              <label className="block text-sm mb-1">
                {input.label}
                {input.required && <span className="text-red-500 ml-1">*</span>}
              </label>
            )}
            {renderInput(input)}
          </div>
        ))}
      </div>

      <div className="flex flex-row space-x-2">
        <DataQButton
          className="ml-auto"
          type="secondary"
          onClick={() => cancel(false)}
        >
          Cancel
        </DataQButton>
        <DataQButton
          type={ExportButtonType || "primary"}
          onClick={handleConfirm}
          disabled={!filename.trim()}
        >
          {ExportButtonText || "Export"}
        </DataQButton>
      </div>
    </Modal>
  )
}

export default ExportModal
