import React, { useState } from "react"
import { useRecoilValue } from "recoil"
import { message, Table, Tooltip } from "antd"

import { useDataImport } from "hooks/useDataImport"
import DataImport from "../DataImport.component"
import DataQButton from "components/UI/Buttons/DataQButton"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"
import LoadingSkeleton from "components/UI/LoadingSkeleton/LoadingSkeleton"
import ExportModal from "components/UI/ExportModal/ExportModal"

import { projectAtom } from "atoms/projectAtom"
import handleSequenceError from "utils/handleSequenceError"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"

/**
 * DataImportTable component renders a table displaying data import status and actions
 * for a given project. It allows users to delete uploaded files or upload missing files.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data object containing the list of data files.
 * @param {boolean} props.isLoading - The loading state for the table.
 * @param {Function} props.refresh - The function to refresh the data after an action.
 * @returns {React.ReactElement} The rendered DataImportTable component.
 */
const DataImportTable = (props) => {
  const { dataFiles, isLoading, refresh } = props
  const { deleteDataImportFile, generateMapFile } = useDataImport()
  const [isGenerateDatamapModalVisible, setGeneratetDatamapModalVisible] =
    useState(false)
  const project = useRecoilValue(projectAtom)

  /**
   * Generates a map file for the current project.
   * It triggers the generateMapFile function and displays a success message on completion.
   * In case of an error, it displays an error notification.
   *
   * @async
   * @function
   * @param {Object} additionalValues - The additional values to pass to the API.
   * @returns {Promise<void>}
   * @throws Will throw an error if the request fails.
   *
   **/
  const generateMap = async (additionalValues) => {
    try {
      const res = await generateMapFile(
        project.id,
        additionalValues.groupSections,
        additionalValues.variableNames
      )
      if (res.data.result.message === "Document successfully uploaded.") {
        message.success("Map generated successfully")
        refresh()
        setGeneratetDatamapModalVisible(false)
      }
    } catch (err) {
      ErrorNotification("Error generating map", err)
    }
  }

  const columns = [
    {
      title: "File Type",
      dataIndex: "fileType",
      key: "fileType",
      width: 100,
    },
    {
      title: "Uploaded",
      dataIndex: "isUploaded",
      key: "isUploaded",
      width: 50,
      render: (_, record) => (
        <div>
          <p>{record.isUploaded ? "Yes" : "No"}</p>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div>
          {record.isUploaded ? (
            <DataQButton onClick={() => deleteFile(record.type)} type="danger">
              Delete
            </DataQButton>
          ) : (
            <>
              <DataImport
                name={`Upload ${record.fileType}`}
                refresh={refresh}
                type={record.type}
              />
              {record.fileType === "Map File" && (
                <>
                  <DataQButton
                    type="primary"
                    className="ml-2"
                    onClick={() => setGeneratetDatamapModalVisible(true)}
                  >
                    Generate Map
                  </DataQButton>
                  <Tooltip title="If this project has a questionnaire file you can use 'Generate Map' to directly generate the datamap or you can upload one directly using 'Upload Map File'">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ml-2 text-yellow-400 cursor-pointer"
                    />
                  </Tooltip>
                </>
              )}
            </>
          )}
        </div>
      ),
    },
  ]

  const deleteFile = async (type) => {
    try {
      const res = await deleteDataImportFile(
        project.id,
        project.Created_by,
        type
      )
      if (handleSequenceError(res.data, "Error Deleting Data File!")) {
        message.success("Data File Deleted Successfully!")
        refresh()
      }
    } catch (error) {
      ErrorNotification("Error deleting data file!", error.message)
    }
  }

  return (
    <>
      <ExportModal
        title="Generate Data Map"
        confirm={generateMap}
        cancel={setGeneratetDatamapModalVisible}
        isVisible={isGenerateDatamapModalVisible}
        message={""}
        noFilename={true}
        additionalInputs={[
          {
            name: "groupSections",
            type: "checkbox",
            checkboxLabel: "Group Questionnaire Sections",
            required: false,
          },
          {
            name: "variableNames",
            type: "select",
            label: "Use as variable names",
            multiple: false,
            defaultValue: "numbering",
            options: [
              { value: "numbering", label: "Numbered automatically" },
              { value: "labels", label: "Generate from questionnaire labels" },
            ],
            required: false,
            disabled: (values) => values.template === true,
          },
        ]}
      />
      <Table
        size="small"
        className="w-full"
        rowKey="key"
        dataSource={dataFiles || []}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: isLoading ? (
            <LoadingSkeleton type="table" columns={columns} />
          ) : (
            "No data"
          ),
        }}
      />
    </>
  )
}

export default DataImportTable
