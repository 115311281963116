import { Extension } from "@tiptap/core"
import Suggestion from "@tiptap/suggestion"
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"

const escapeHtml = (str) => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
}

const commands = {
  PDUMP: {
    params: [],
    description:
      "Outputs a snapshot of the project's current state, including respondents, variables, log status, and project size.",
  },
  DUMP: {
    params: ["<name>"],
    description:
      "Outputs the entire data structure or specific data in JSON format.",
  },
  SDUMP: {
    params: ["<name>"],
    description: "Outputs a summarized JSON of the data structure.",
  },
  INFO: {
    params: ["<name>"],
    description:
      "Provides detailed information about the specified name or subname.",
  },
  OUTPUT: {
    params: ["<name>"],
    description: "Outputs the variable as JSON or Table format for XTAB/DTAB.",
  },
  OUTPUTJ: {
    params: ["<name>"],
    description: "Similar to OUTPUT but presents data in JSON format.",
  },
  XTAB: {
    params: ["<name>", "<var1>", "<var2>"],
    description: "Creates a crosstab of two variables.",
  },
  DTAB: {
    params: ["<name>", "<var1>", "<var2>"],
    description: "Generates a detailed data table for the specified variables.",
  },
  QTABLE: {
    params: ["<name>"],
    description:
      "Creates a root or nested QTable. QTables act as containers within the tree structure.",
  },
  FIELD: {
    params: ["<QTable>::<Field>", "<Type>"],
    description:
      "Creates a new field with a specified type and value under a QTable. Fields hold actual data and represent leaves of the tree.",
    types: ["INT", "REAL", "TEXT", "BOOL"],
  },
  REMOVE: {
    params: ["<name>"],
    description: "Removes a QTable or a field from a QTable.",
  },
  COPY: {
    params: ["<to>", "<from>"],
    description: "Copies data from one QTable or field to another.",
  },
  COPYSTRUCT: {
    params: ["<TargetQTable>", "<SourceQTable>"],
    description:
      "Copies the structure (without data) from a source QTable to a target QTable.",
  },
  APPEND: {
    params: ["<target_QTable>", "<source_field_or_QTable>"],
    description: "Adds content from one QTable/field to another.",
  },
  RENAME: {
    params: ["<QTABLE_NAME>", "<NEW_LABEL>"],
    description: "Renames a QTable or field.",
  },
  NOT: {
    params: ["<FIELD>"],
    description:
      "Reverses the values of a boolean field (false -> true, true -> false).",
  },
  BLANKTRUE: {
    params: ["<FIELD>"],
    description: "Changes Blank/Null values to true.",
  },
  BLANKFALSE: {
    params: ["<FIELD>"],
    description: "Changes Blank/Null values to false.",
  },
  BLANKZERO: {
    params: ["<FIELD>"],
    description: "Changes Blank/Null values to 0 for INT and REAL fields.",
  },
  SETBLANK: {
    params: ["<FIELD>"],
    description: "Changes all values to null.",
  },
  ASSIGN: {
    params: ["<target>", "<source>"],
    description:
      "Assigns values from one field/QTable to another or assigns a literal value. Supported types: INT, BOOL, REAL, TEXT.",
  },
  ADD: {
    params: ["<target>", "<source>"],
    description:
      "Adds values from one field/QTable to another. Supported types: INT, REAL.",
  },
  SUBTRACT: {
    params: ["<target>", "<source>"],
    description:
      "Subtracts values from one field/QTable from another. Supported types: INT, REAL.",
  },
  MULT: {
    params: ["<target>", "<source>"],
    description:
      "Multiplies values from one field/QTable with another. Supported types: INT, REAL.",
  },
  DIV: {
    params: ["<target>", "<source>"],
    description:
      "Divides values from one field/QTable by another. Supported types: INT, REAL.",
  },
  AND: {
    params: ["<target>", "<source>"],
    description:
      "Performs a logical AND operation on boolean fields. Supported type: BOOL.",
  },
  OR: {
    params: ["<target>", "<source>"],
    description:
      "Performs a logical OR operation on boolean fields. Supported type: BOOL.",
  },
  CODEBOOK: {
    params: [],
    description:
      "Provides a concise summary of headers/metadata without displaying actual data.",
  },
  RESET: {
    params: [],
    description: "Resets the dataset to its original state.",
  },
  CLEAR: {
    params: [],
    description: "Clears the entire dataset, deleting all QTables and fields.",
  },
  STOP: {
    params: [],
    description: "Terminates the execution of a script.",
  },
  FIND: {
    params: ["[search_string]"],
    description:
      "Searches for strings within the labels of fields/QTables. With EXTENDED keyword, also searches additional info.",
  },
  FINDR: {
    params: ["[regular_expression]"],
    description:
      "Searches for a regex pattern within the labels of fields/QTables. With EXTENDED keyword, also searches additional info.",
  },
  SCOPE: {
    params: ["<QTABLE>"],
    description: "Redefines the root node for subsequent operations.",
  },
  PROJECT: {
    params: ["<JSON>"],
    description: "Creates a new project using the specified JSON structure.",
  },
  REMEMBER: {
    params: ["<QTABLE>"],
    description: "Saves a QTable's state to recall later using RECALL.",
  },
  RECALL: {
    params: ["<QTABLE>"],
    description:
      "Recalls a QTable's state, allowing a revert to the original state when REMEMBER was called.",
  },
  FORGET: {
    params: ["<QTABLE>"],
    description: "Forgets the snapshot created using REMEMBER.",
  },
  ALIAS: {
    params: ["<alias_name>", "<QTable::Field>"],
    description: "Creates an alias for a QTable or field.",
  },
  FFIELD: {
    params: ["<FieldName>", "<QTableName>"],
    description:
      "Creates a boolean field based on conditions. Useful for filtering data across multiple QTables.",
  },
  USEWEIGHTS: {
    params: ["<WeightsColumn>"],
    description: "Applies weighting to data using the specified column.",
  },
  CALCWEIGHTS: {
    params: ["<FIELD>", "<weighting_factor>"],
    description: "Calculates and applies weighting factors to the data.",
  },
}

const renderItems = (items, selectedIndex) => {
  return `
    <div class="suggestions">
      ${items
        .map(
          (item, index) => `
        <div class="suggestion-item ${
          index === selectedIndex ? "selected" : ""
        }" data-index="${index}">
          <div class="suggestion-title">
            ${escapeHtml(item.title)}
            ${
              item.params.length > 0
                ? `<span class="suggestion-params">${item.params
                    .map((param) => escapeHtml(param))
                    .join(" ")}</span>`
                : ""
            }
          </div>
          <div class="suggestion-description">${escapeHtml(
            item.description
          )}</div>
        </div>
      `
        )
        .join("")}
    </div>
  `
}

export const CommandSuggestion = Extension.create({
  name: "commandSuggestion",

  addOptions() {
    return {
      suggestion: {
        char: "/", // Changed from " " to "/"
        startOfLine: false, // Removed startOfLine requirement
        command: ({ editor, range, props }) => {
          // This is the original command callback, now we rely on a custom function in onKeyDown/click
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent(props.title + " ")
            .run()

          return true
        },
        items: ({ editor, query }) => {
          const currentLine = editor.state.doc
            .textBetween(
              editor.state.selection.$head.before(),
              editor.state.selection.$head.pos
            )
            .trim()

          if (!currentLine || currentLine === "/") {
            return Object.entries(commands).map(([command, details]) => ({
              title: command,
              description: details.description,
              params: details.params || [],
              types: details.types,
            }))
          }

          return Object.entries(commands)
            .filter(([command]) =>
              command.toLowerCase().startsWith(query.toLowerCase())
            )
            .map(([command, details]) => ({
              title: command,
              description: details.description,
              params: details.params || [],
              types: details.types,
            }))
        },
        render: () => {
          let popup
          let element
          let selectedIndex = 0
          let items = []
          let editorInstance
          let rangeInstance

          const selectItem = (index) => {
            selectedIndex = index
            element.innerHTML = renderItems(items, selectedIndex)
            attachClickHandlers() // Re-attach click handlers after re-render
            // Scroll the selected item into view
            const selectedItem = element.querySelector(
              ".suggestion-item.selected"
            )
            if (selectedItem) {
              selectedItem.scrollIntoView({
                block: "nearest",
                inline: "nearest",
              })
            }
          }

          // A helper function to insert the selected item into the editor
          const insertSelectedItem = () => {
            const item = items[selectedIndex]
            if (item && editorInstance && rangeInstance) {
              popup[0].hide()
              setTimeout(() => {
                const paramsString =
                  item.params && item.params.length > 0
                    ? " " + item.params.join(" ")
                    : ""

                editorInstance
                  .chain()
                  .focus()
                  .deleteRange(rangeInstance)
                  .insertContent(item.title + paramsString + " ")
                  .run()
              }, 0)
            }
          }

          const attachClickHandlers = () => {
            const suggestionItems = element.querySelectorAll(".suggestion-item")
            suggestionItems.forEach((el) => {
              el.addEventListener("click", () => {
                const index = Number(el.getAttribute("data-index"))
                selectedIndex = index
                insertSelectedItem()
              })
            })
          }

          return {
            onStart: (props) => {
              editorInstance = props.editor
              rangeInstance = props.range

              element = document.createElement("div")
              items = props.items
              element.innerHTML = renderItems(items, selectedIndex)

              popup = tippy("body", {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
                arrow: true,
                theme: "dataq",
              })

              attachClickHandlers()
            },
            onUpdate(props) {
              items = props.items
              element.innerHTML = renderItems(items, selectedIndex)
              rangeInstance = props.range
              attachClickHandlers()
            },
            onKeyDown(props) {
              const { event } = props

              if (event.key === "Escape") {
                popup[0].hide()
                return true
              }

              if (event.key === "ArrowUp") {
                event.preventDefault()
                selectItem(Math.max(selectedIndex - 1, 0))
                return true
              }

              if (event.key === "ArrowDown") {
                event.preventDefault()
                selectItem(Math.min(selectedIndex + 1, items.length - 1))
                return true
              }

              if (event.key === "Enter" || event.key === "Tab") {
                event.preventDefault()
                insertSelectedItem()
                return true
              }

              return false
            },
            onExit() {
              popup[0].destroy()
            },
          }
        },
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

const suggestionStyles = `
.tippy-box[data-theme~='dataq'] {
  background-color: #f3f3f3; /* Light gray background */
  color: #333333; /* Dark text for contrast on gray background */
  border: 1px solid #ccc; /* Slightly darker gray border */
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tippy-box[data-theme~='dataq'] .tippy-arrow {
  display: none; /* Hide the default arrow */
}

.suggestions {
  padding: 0.25rem;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  border: 0;

  /* For Firefox */
  scrollbar-width: thin;  
  scrollbar-color: rgba(0,0,0,0.3) transparent; 
}

/* For WebKit Browsers (Chrome, Safari, etc.) */
.suggestions::-webkit-scrollbar {
  width: 4px;
}

.suggestions::-webkit-scrollbar-track {
  background: transparent;
}

.suggestions::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.suggestions::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.suggestions::-webkit-scrollbar-corner {
  background: transparent;
}


.suggestion-item {
  padding: 0.5rem;
  margin: 0.1rem 0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  background-color: #ffffff;
  font-size: 0.85rem;
  line-height: 1.2;
  color: #333333; /* Darker text on light background */
}

.suggestion-item:hover {
  background-color: #f5f5f5; /* Light gray hover background */
  border-color: #cccccc;
}

.suggestion-item.selected {
  background-color: #e9f7fd; /* Very light blue to indicate selection */
  border-color: #bcdff5;
}

.suggestion-title {
  font-weight: 600;
  color: #36c3ed; /* Main theme color for title */
  font-size: 0.85rem;
  margin-bottom: 0.15rem;
}

.suggestion-params {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  color: #555555;
  background-color: #efefef;
  padding: 0.1rem 0.3rem;
  border-radius: 4px;
  margin-left: 0.3rem;
  font-size: 0.75rem;
  display: inline-block;
  white-space: nowrap;
}

.suggestion-description {
  font-size: 0.75rem;
  color: #666666;
  line-height: 1.2;
}

.suggestions::-webkit-scrollbar {
  width: 6px;
}

.suggestions::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.suggestions::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 4px;
}

.suggestions::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}
`

const style = document.createElement("style")
style.textContent = suggestionStyles
document.head.appendChild(style)
