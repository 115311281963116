import axios from "../request"

/**
 * Downloads a data map file in CSV format.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data map to be downloaded.
 * @param {string} filename - The desired filename for the downloaded CSV file.
 * @param {boolean} template - Indicates whether to download as a template.
 * @param {boolean} groupSections - Indicates whether to group sections in the download.
 * @param {string} variableNames - The type of variable names to download.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the download fails.
 */
export async function downloadDatamap(
  token,
  user,
  unit,
  role,
  id,
  filename,
  template,
  groupSections,
  variableNames
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
    params: {}, // Initialize query parameters
    responseType: "blob", // This is important to handle the file download
  }

  // Add query parameters to config.params without values
  if (groupSections) {
    config.params.group_sections = "" // or undefined
  }

  if (template) {
    config.params.template = "" // or undefined
  }

  // Only add variableNames if template is not selected
  if (!template && variableNames) {
    config.params.var = variableNames // Will be either 'numbering' or 'labels'
  }

  const response = await axios.post(
    `/download-datamap`,
    { id }, // Keep id in the post body
    config
  )

  // Create a URL for the blob
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${filename}.map.csv`) // Set the filename
  document.body.appendChild(link)
  link.click()
  link.remove()
  return response
}

/**
 * Initializes data reporting for project.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the project to initialize data reporting for.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the initialization fails.
 */
export async function InitialiseDataReporting(token, user, unit, role, id) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(`/initialise-data?id=${id}`, config)
  return response
}

/**
 * Runs a query on a specific engine instance.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} instanceID - The identifier of the query instance to run the query on. Returned from initialise-data.
 * @param {string} query - The query to be executed.
 * @param {boolean} isAsync - Indicates whether the query should be run asynchronously.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the query execution fails.
 */
export async function RunQuery(
  token,
  user,
  unit,
  role,
  instanceID,
  query,
  isAsync
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  // Conditionally add the ASync-Request header if isAsync is true
  if (isAsync) {
    config.headers["ASync-Request"] = 10
  }

  const response = await axios.post(
    `/query-run?${instanceID}`,
    { query: query },
    config
  )

  return response
}

/**
 * TODO - move to a more appropriate location in the codebase as this is not related to data reporting. But only one call currently uses ASync-Request header.
 * Polls the server to check if an asynchronous request is ready.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} asyncID - The identifier for the asynchronous request to check readiness for.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if polling fails.
 */
export async function pollAsync(token, user, unit, role, asyncID) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(`/async-ready?id=${asyncID}`, config)
  return response
}

/**
 *
 * TODO - move to a more appropriate location in the codebase as this is not related to data reporting. But only one call currently uses ASync-Request header.
 * Fetches the result of a completed asynchronous request.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} asyncID - The identifier for the asynchronous request whose result is being fetched.
 * @returns {Promise<Object>} - The response object from the API call containing the result data.
 * @throws Will throw an error if fetching the result fails.
 */
export async function fetchAsyncResult(token, user, unit, role, asyncID) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(`/async-get?id=${asyncID}`, config)
  return response
}
