import axios from "../request"

/**
 * Converts data to Decipher XML format and triggers a download of the resulting XML file.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be converted.
 * @param {Object} options - Additional options for the conversion, including filename.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the conversion or download fails.
 */
export async function convertToDecipherXML(
  token,
  user,
  unit,
  role,
  id,
  options
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
    responseType: "blob", // This is important to handle the file download
  }
  const response = await axios.post(
    `/convert-decipher-xml`,
    { id: id, options: options },
    config
  )

  // Create a URL for the blob
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${options.filename}.xml`) // Set the filename
  document.body.appendChild(link)
  link.click()
  link.remove()

  return response
}

/**
 * Converts data to a Word document format.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be converted.
 * @param {Object} options - Additional options for the conversion.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the conversion fails.
 */
export async function convertToWord(token, user, unit, role, id, options) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.post(
    `/convert-word`,
    { id: id, options: options },
    config
  )

  return response
}

/**
 * Converts data to HTML or Word format and triggers a download of the resulting file.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the user.
 * @param {string} id - The identifier of the data to be converted.
 * @param {Object} options - Additional options for the conversion.
 * @param {string} filename - The desired filename for the downloaded file.
 * @param {string} type - The type of conversion - 'word' or 'html'.
 * @param {string} variableNames - The type of variable names to export.
 * @returns {Promise<Object>} - The response object from the API call.
 * @throws Will throw an error if the conversion or download fails.
 */
export async function downloadHTML(
  token,
  user,
  unit,
  role,
  id,
  options,
  filename,
  type,
  variableNames
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
        "Content-Type": "application/json",
      },
      params: {}, // Initialize params object
      responseType: "text", // Get response as text since server returns HTML
    }
    if (variableNames) {
      config.params.var = variableNames // Will be either 'numbering' or 'labels'
    }
    const response = await axios.post(
      "/convert-word",
      { id: id, options: options },
      config
    )

    // The server returns HTML content
    const htmlContent = response.data

    let blob
    let fileExtension

    if (type === "word") {
      // Create a minimal Word document structure
      const header =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" ' +
        'xmlns:w="urn:schemas-microsoft-com:office:word" ' +
        'xmlns="http://www.w3.org/TR/REC-html40">' +
        '<head><meta charset="utf-8"></head><body>'
      const footer = "</body></html>"
      const sourceHTML = header + htmlContent + footer

      blob = new Blob(["\ufeff", sourceHTML], {
        type: "application/msword",
      })
      fileExtension = "doc"
    } else {
      // Save as HTML
      blob = new Blob([htmlContent], { type: "text/html" })
      fileExtension = "html"
    }

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${filename}.${fileExtension}`)
    document.body.appendChild(link)
    link.click()

    // Clean up
    window.URL.revokeObjectURL(url)
    link.remove()

    return response
  } catch (error) {
    console.error("Error in downloadFile:", error)
    throw error
  }
}
