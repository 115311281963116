import axios from "../request"
import { downloadFile } from "utils/downloadFile"

/**
 * Exports data as a JSON file from the given instance and triggers a download.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user making the request.
 * @param {string} unit - The user's unit or department.
 * @param {string} role - The role of the user making the request.
 * @param {string} instanceID - The ID of the instance to export.
 * @param {string} filename - The name to use for the downloaded file (without extension).
 * @throws Will throw an error if the request fails.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
export async function exportToJSON(
  token,
  user,
  unit,
  role,
  instanceID,
  filename
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
    responseType: "blob", // Important to download files as binary
  }
  const response = await axios.get(
    `/export-json?instanceId=${instanceID}`,
    config
  )
  downloadFile(response, filename ? `${filename}.json` : "export.json")
  return response
}

/**
 * Exports data as a SQL file from the given instance and triggers a download.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user making the request.
 * @param {string} unit - The user's unit or department.
 * @param {string} role - The role of the user making the request.
 * @param {string} instanceID - The ID of the instance to export.
 * @param {string} sqlType - The type of SQL export ("wide" or "narrow").
 * @param {string} project - The project name associated with the SQL export.
 * @param {string} filename - The name to use for the downloaded file (without extension).
 * @throws Will throw an error if the request fails.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
export async function exportToSQL(
  token,
  user,
  unit,
  role,
  instanceID,
  sqlType,
  project,
  filename
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
    responseType: "blob", // Important to download files as binary
  }
  const response = await axios.get(
    `/export-sql?instanceId=${instanceID}&sqlType=${sqlType}&project=${project}`,
    config
  )
  downloadFile(response, filename ? `${filename}.sql` : "export.sql")
  return response
}

/**
 * Exports data as an SPS file from the given instance and triggers a download.
 *
 * @param {string} token - The authentication token.
 * @param {string} user - The user making the request.
 * @param {string} unit - The user's unit or department.
 * @param {string} role - The role of the user making the request.
 * @param {string} instanceID - The ID of the instance to export.
 * @param {string} varType - The variable type to include in the export ("numbering" or "labels").
 * @param {string} filename - The name to use for the downloaded file (without extension).
 * @throws Will throw an error if the request fails.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
export async function exportToSPS(
  token,
  user,
  unit,
  role,
  instanceID,
  varType,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "text", // Expecting base64 encoded string
    }

    const response = await axios.get(
      `/export-sps?instanceId=${instanceID}&varType=${varType}&spsType=sps_dat`,
      config
    )

    // Get the base64-encoded string and trim any whitespace
    const base64Data = response.data.trim()

    // Convert base64 to binary
    const binaryString = window.atob(base64Data)
    const len = binaryString.length
    const uint8Array = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
    }

    // Create blob from the binary data
    const blob = new Blob([uint8Array], { type: "application/zip" })

    // Create download link
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = `${filename || "export"}.zip`

    // Trigger download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    window.URL.revokeObjectURL(url)
    link.remove()

    return response
  } catch (error) {
    console.error("Error downloading zip file:", error)
    throw error
  }
}
