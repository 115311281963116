import React, { memo, useCallback, useMemo } from "react"
import { List } from "antd"
import { useQueries } from "hooks/useQueries"
import { SuccessNotification } from "components/UI/Notifications/NotificationTemplate.component"
import handleSequenceError from "utils/handleSequenceError"
import { escapeHTML } from "utils/escapeHtml"
import QueryListItem from "./QueryListItem.component"

/**
 * SavedQueries component displays a list of saved queries with create and delete functionality.
 * Optimized for performance with memoization and batched state updates.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.project - Project information containing id and creator
 * @param {Function} props.toggleSaved - Function to toggle between saved queries view and editor
 * @param {Object} props.editor - TipTap editor instance
 * @param {string[]} props.projectQueries - Array of saved query names
 * @param {Function} props.refreshQueries - Function to refresh the queries list
 * @param {string} props.selectedQuery - Currently selected query name
 * @param {Function} props.setSelectedQuery - Function to update selected query
 * @param {Function} props.setContentChanged - Function to update content changed state
 * @returns {JSX.Element} Rendered SavedQueries component
 */
const SavedQueries = ({
  project,
  toggleSaved,
  editor,
  projectQueries,
  refreshQueries,
  selectedQuery,
  setSelectedQuery,
  setContentChanged,
}) => {
  const { getQuery, deleteQuery } = useQueries()

  /**
   * Handles selection of a saved query.
   * Loads query content into editor and updates state.
   *
   * @async
   * @param {Event|null} e - Event object (if triggered by click)
   * @param {string} item - Query name to load
   */
  const handleSelectSavedQuery = useCallback(
    async (e, item) => {
      try {
        const queryRes = await getQuery(project.id, project.Created_by, item)
        const content = queryRes.data
          .split("\n")
          .map((line) => `<p>${escapeHTML(line)}</p>`)
          .join("")

        // Batch state updates
        React.startTransition(() => {
          editor.commands.clearContent()
          editor.commands.insertContent(content)
          setSelectedQuery(item)
          setContentChanged(false)
          toggleSaved()
        })
      } catch (error) {
        console.error(`Error in API call:`, error)
        throw error
      }
    },
    [
      project.id,
      project.Created_by,
      editor,
      setSelectedQuery,
      setContentChanged,
      toggleSaved,
    ]
  )

  /**
   * Handles deletion of a saved query.
   * Updates list after successful deletion.
   *
   * @async
   * @param {React.MouseEvent} e - Click event
   * @param {string} item - Query name to delete
   */
  const deleteSavedQuery = useCallback(
    async (e, item) => {
      try {
        e.stopPropagation()
        const deleteRes = await deleteQuery(
          project.id,
          project.Created_by,
          item
        )
        if (handleSequenceError(deleteRes.data, "Error deleting query")) {
          React.startTransition(() => {
            setSelectedQuery("")
            refreshQueries()
            SuccessNotification(
              "Query Deleted successfully!",
              "Query has been permanently deleted."
            )
          })
        }
      } catch (error) {
        console.error(`Error in API call:`, error)
        throw error
      }
    },
    [project.id, project.Created_by, deleteQuery, refreshQueries]
  )

  /**
   * Handles creation of a new query.
   * Clears editor and resets state.
   */
  const handleCreateNew = useCallback(() => {
    React.startTransition(() => {
      editor.commands.clearContent()
      setSelectedQuery("")
      toggleSaved()
    })
  }, [editor, setSelectedQuery, toggleSaved])

  // Memoize the items array to prevent unnecessary recalculations
  const allItems = useMemo(
    () => [
      { key: "new", title: "Create New Query", isCreateNew: true },
      ...projectQueries.map((query) => ({
        key: query,
        title: query,
        isCreateNew: false,
      })),
    ],
    [projectQueries]
  )

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto">
        <List
          itemLayout="horizontal"
          dataSource={allItems}
          renderItem={(item) => (
            <QueryListItem
              key={item.key}
              item={item}
              selectedQuery={selectedQuery}
              onDelete={deleteSavedQuery}
              onSelect={handleSelectSavedQuery}
              onCreate={handleCreateNew}
            />
          )}
        />
      </div>
    </div>
  )
}

export default memo(SavedQueries)
