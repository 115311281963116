import React, { useState } from "react"
import { Menu, Tooltip, message } from "antd"
import { useRecoilValue } from "recoil"

import { useEngine } from "hooks/useEngine"
import { useTemplates } from "hooks/useTemplates"

import items from "./MenuItems"
import actions from "./MenuActions"

import DataQButton from "components/UI/Buttons/DataQButton"
import ProgressModal from "components/UI/ProgressModal/ProgressModal"
import ExportToDecipherModal from "./ExportToDecipher/ExportToDecipherModal.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRightToBracket,
  faListOl,
} from "@fortawesome/free-solid-svg-icons"

import { projectAtom } from "atoms/projectAtom"
import TemplatesModal from "./Templates/TemplatesModal.component"
import ExportModal from "components/UI/ExportModal/ExportModal"

/**
 * Functional component representing the menu bar for the questionnaire editor.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.inMaster - Indicates if the template should be used in the master configuration.
 * @param {boolean} props.qnrEmpty - Indicates if the questionnaire is empty.
 * @param {Object} props.editor - The editor instance used to manipulate the questionnaire.
 * @param {boolean} props.highlightingEnabled - Indicates if the highlighting is enabled.
 * @param {Function} props.toggleHighlighting - Function to toggle the highlighting feature.
 * @param {Array} props.variants - The variants data that will be passed to the export form.
 * @param {Function} props.checkQuestionnaire - Function to check the questionnaire.
 * @param {Function} props.getQuestionnaireNotes - Function to retrieve the notes for the questionnaire.
 * @param {Function} props.toggleLineNumbers - Function to toggle the line numbers in the editor.
 * @param {boolean} props.lineNumbersEnabled - Indicates if the line numbers are enabled.
 * @returns {JSX.Element} The rendered menu bar component.
 */
const QuestionnaireMenuBar = (props) => {
  const project = useRecoilValue(projectAtom)
  const {
    inMaster,
    qnrEmpty,
    editor,
    highlightingEnabled,
    toggleHighlighting,
    variants,
    checkQuestionnaire,
    getQuestionnaireNotes,
    toggleLineNumbers,
    lineNumbersEnabled,
  } = props
  const [isExportDecipherModalVisible, setExportDecipherModalVisible] =
    useState(false)
  const [isTemplateModalVisible, setTemplateModalVisible] = useState(false)
  const [isExportDatamapModalVisible, setIsExportDatamapModalVisible] =
    useState(false)
  const [
    isExportQuestionnaireModalVisible,
    setIsExportQuestionnaireModalVisible,
  ] = useState(false)

  const [templateFiles, setTemplateFiles] = useState({})

  const { downloadDatamapCSV, isDownloadingDatamapCSV, exportToHTML } =
    useEngine()
  const { listTemplateFiles, listingTemplateFiles } = useTemplates()

  /**
   * Handles the click event on menu items.
   *
   * @param {Object} e - The click event object.
   * @param {string} e.key - The key of the clicked menu item.
   */
  const onClick = async (e) => {
    const action = actions(editor)[e.key]

    if (e.key === "template") {
      try {
        setTemplateModalVisible(true)
        const templateRes = await listTemplateFiles()
        setTemplateFiles(templateRes.data.result)
      } catch (error) {
        message.error("Template failed to be parsed!")
        console.error("Error parsing template:", error)
      }
    } else if (e.key === "export_to_decipher_xml") {
      setExportDecipherModalVisible(true)
    } else if (e.key === "export_data_map") {
      setIsExportDatamapModalVisible(true)
    } else if (e.key === "export_questionnaire") {
      setIsExportQuestionnaireModalVisible(true)
    } else if (action) {
      action()
    }
  }

  /** TODO Refactor this function to be in a helper file with same function in ExportToDecipherOptionsForm.component.jsx
   * Extracts unique languages from an array of variants and a project object.
   * @param {Variant[]} variants - An array of variant objects.
   * @param {Object} project - A project object containing languages.
   * @returns {string[]} An array of unique languages.
   */
  const getUniqueLanguages = (variants, project) => {
    const variantLanguages = variants.flatMap((variant) => variant.languages)
    const allLanguages = [project.Designlanguage, ...variantLanguages]
    return [...new Set(allLanguages)]
  }

  // Default to MASTER if variants array is empty, or if only one language, default it
  const variantOptions =
    variants.length > 0
      ? variants.map((variant) => ({
          label: variant.name,
          value: variant.name,
        }))
      : [{ label: "MASTER", value: "MASTER" }]

  const languageOptions = getUniqueLanguages(variants, project).map(
    (language) => ({
      label: language,
      value: language,
    })
  )

  /**
   * Confirms the export of a data map template.
   *
   * @param {string} filename - The name of the file for the export.
   * @param {boolean} additionalValues.groupSections - Boolean indicating whether to group sections in the export.
   * @param {boolean} additionalValues.template - Boolean indicating whether to export as a template.
   * @param {string} additionalValues.variableNames - Type of variable names to export (numbering or labels).
   * @returns {Promise<void>}
   */
  const confirmDatamapExport = async (filename, additionalValues) => {
    try {
      await downloadDatamapCSV(
        project.id,
        filename,
        additionalValues.template,
        additionalValues.groupSections,
        additionalValues.variableNames
      )
      setIsExportDatamapModalVisible(false)
      message.success("Datamap created successfully!")
    } catch (error) {
      message.error("Datamap failed to be created!")
      console.error("Error creating datamap:", error)
    }
  }

  /**
   * Confirms the export of Questionnaire.
   *
   * @param {string} filename - The name of the file for the export.
   * @param {Object} additionalValues - Additional options for the export. (language,variant and type)
  
   * @returns {Promise<void>}
   */
  const confirmQuestionanireExport = async (filename, additionalValues) => {
    const { type, variableNames, ...options } = additionalValues // Destructure type and keep rest as options
    try {
      await exportToHTML(project.id, options, filename, type, variableNames)
      setIsExportQuestionnaireModalVisible(false)
      message.success("Questionnaire Exported successfully!")
    } catch (error) {
      message.error("Questionnaire failed to be created!")
      console.error("Error creating Questionanire:", error)
    }
  }

  return (
    <>
      <ExportModal
        title="Export Data Map"
        confirm={confirmDatamapExport}
        cancel={setIsExportDatamapModalVisible}
        isVisible={isExportDatamapModalVisible}
        message={"Please enter a filename for your data map export"}
        additionalInputs={[
          {
            name: "template",
            type: "checkbox",
            checkboxLabel: "Only Export Template",
            required: false,
          },
          {
            name: "groupSections",
            type: "checkbox",
            checkboxLabel: "Group Questionnaire Sections",
            required: false,
          },
          {
            name: "variableNames",
            type: "select",
            label: "Use as variable names",
            multiple: false,
            defaultValue: "numbering",
            options: [
              { value: "numbering", label: "Numbered automatically" },
              { value: "labels", label: "Generate from questionnaire labels" },
            ],
            required: false,
            disabled: (values) => values.template === true,
          },
        ]}
      />
      <ExportModal
        title="Export Questionnaire"
        confirm={confirmQuestionanireExport}
        cancel={setIsExportQuestionnaireModalVisible}
        isVisible={isExportQuestionnaireModalVisible}
        message={"Please enter a filename for your questionnaire export"}
        additionalInputs={[
          {
            name: "language",
            type: "select",
            label: "Language",
            multiple: false,
            defaultValue:
              languageOptions.length === 1
                ? languageOptions[0].value
                : undefined,
            options: languageOptions,
            required: true,
          },
          {
            name: "variant",
            type: "select",
            label: "Variant",
            multiple: false,
            defaultValue:
              variantOptions.length === 1 ? variantOptions[0].value : undefined,
            options: variantOptions,
            required: true,
          },
          {
            name: "type",
            type: "select",
            label: "Export Type",
            multiple: false,
            defaultValue: "word",
            options: [
              { value: "html", label: "HTML" },
              { value: "word", label: "Word" },
            ],
            required: true,
          },
          {
            name: "variableNames",
            type: "select",
            label: "Use as variable names",
            multiple: false,
            defaultValue: "numbering",
            options: [
              { value: "numbering", label: "Numbered automatically" },
              { value: "labels", label: "Generate from questionnaire labels" },
            ],
            required: false,
            disabled: (values) => values.template === true,
          },
          {
            name: "style",
            type: "select",
            label: "Style",
            multiple: false,
            defaultValue: "default",
            options: [{ value: "default", label: "Default DATA(Q)" }],
          },
        ]}
      />
      <ProgressModal
        title={"Creating Datamap"}
        isLoading={isDownloadingDatamapCSV}
        duration={100}
      />
      <div className="flex flex-row w-full border">
        <ExportToDecipherModal
          getQuestionnaireNotes={getQuestionnaireNotes}
          isExportDecipherModalVisible={isExportDecipherModalVisible}
          setExportDecipherModalVisible={setExportDecipherModalVisible}
          project={project}
          variants={variants}
        />
        <TemplatesModal
          inMaster={inMaster}
          qnrEmpty={qnrEmpty}
          isTemplateModalVisible={isTemplateModalVisible}
          setTemplateModalVisible={setTemplateModalVisible}
          listingTemplateFiles={listingTemplateFiles}
          templateFiles={templateFiles}
          editor={editor}
        />
        <Menu
          className="border-gray-300 w-full"
          onClick={onClick}
          selectedKeys={[]}
          mode="horizontal"
          items={items}
        />

        <div className="flex ml-auto border-b pointer-events-auto">
          <Tooltip title="Toggle Highlighting" placement="bottomRight">
            <div className="flex">
              <DataQButton
                type={highlightingEnabled ? "primary" : "secondary"}
                className="w-7 !h-7 !p-0 my-auto mr-2"
                onClick={() => toggleHighlighting()}
              >
                (Q)
              </DataQButton>
            </div>
          </Tooltip>
          <Tooltip title="Toggle Line Numbers" placement="bottomRight">
            <div className="flex">
              <DataQButton
                type={lineNumbersEnabled ? "primary" : "secondary"}
                className="w-7 !h-7 !p-0 my-auto mr-2"
                onClick={() => toggleLineNumbers()}
              >
                <FontAwesomeIcon icon={faListOl} />
              </DataQButton>
            </div>
          </Tooltip>
          <Tooltip title="Check Questionnaire" placement="bottomRight">
            <div className="flex">
              <DataQButton
                type="secondary"
                className="w-7 !h-7 !p-0 my-auto mr-2"
                onClick={() => checkQuestionnaire(project.id)}
              >
                <FontAwesomeIcon icon={faArrowRightToBracket} />
              </DataQButton>
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default QuestionnaireMenuBar
