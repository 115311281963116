import React from "react"
import { Select } from "antd"
import { CheckOutlined, PlusOutlined } from "@ant-design/icons"
import { useQueries } from "hooks/useQueries"
import { escapeHTML } from "utils/escapeHtml"
import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

const QueryStatus = ({
  projectQueries,
  selectedQuery,
  editor,
  setContentChanged,
  setSelectedQuery,
}) => {
  const project = useRecoilValue(projectAtom)
  const { getQuery } = useQueries()

  const handleSelectSavedQuery = async (e, item) => {
    if (item === "create_new") {
      handleCreateNew()
      return
    }
    try {
      const queryRes = await getQuery(project.id, project.Created_by, item)
      editor.commands.clearContent()
      const content = queryRes.data
        .split("\n")
        .map((line) => `<p>${escapeHTML(line)}</p>`)
        .join("")
      editor.commands.insertContent(content)
      setSelectedQuery(item)
      setContentChanged(false)
    } catch (error) {
      console.error(`Error in API call:`, error)
      throw error
    }
  }

  const handleCreateNew = () => {
    editor.commands.clearContent()
    setSelectedQuery("")
  }

  const allOptions = [
    {
      value: "create_new",
      label: (
        <div
          className={`flex justify-between items-center ${
            !selectedQuery
              ? "opacity-50 cursor-not-allowed"
              : "text-[#36C3ED] hover:text-[#36C3ED]/80"
          }`}
        >
          <span>New</span>
          <PlusOutlined />
        </div>
      ),
      disabled: !selectedQuery,
    },
    ...(projectQueries?.map((query) => ({
      value: query,
      label: (
        <div className="flex justify-between items-center">
          <span>{query}</span>
          {query === selectedQuery && (
            <CheckOutlined className="text-[#36C3ED]" />
          )}
        </div>
      ),
    })) || []),
  ]

  return (
    <div className="flex my-auto p-2">
      <p className="font-semibold my-auto mr-2">Query Selected:</p>

      <Select
        value={selectedQuery || undefined}
        placeholder="No query selected"
        style={{ width: 200 }}
        options={allOptions}
        onSelect={(value) => handleSelectSavedQuery(null, value)}
      />
    </div>
  )
}

export default QueryStatus
