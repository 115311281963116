import React from "react"
import { Form, Input, Select, message } from "antd"
import DataQButton from "components/UI/Buttons/DataQButton"
import { useEngine } from "hooks/useEngine"

/**
 * Represents a variant object with language information.
 * @typedef {Object} Variant
 * @property {string} name - The name of the variant.
 * @property {string[]} languages - An array of languages associated with the variant.
 * @property {string} content - The content of the variant.
 */
/**
 * ExportToDecipherOptionsForm component renders a form for configuring options
 * to export a project to Decipher XML format.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.project - The project data that will be exported.
 * @param {Function} props.setExportDecipherModalVisible - Function to toggle the visibility of the export modal.
 * @param {Array} props.variants - The list of available variants for the project.
 * @param {Function} props.getQuestionnaireNotes - Function to retrieve the notes for the questionnaire.
 * @returns {JSX.Element} The rendered ExportToDecipherOptionsForm component.
 *
 */
const ExportToDecipherOptionsForm = (props) => {
  const {
    project,
    setExportDecipherModalVisible,
    variants,
    getQuestionnaireNotes,
  } = props
  const [form] = Form.useForm()
  const { convertToXMLDecipher, isConvertingToXMLDecipher } = useEngine()

  /**
   * Handles the form submission and triggers the export to Decipher XML.
   *
   * @async
   * @function submitDecipherOptions
   * @param {Object} formValues - The values from the form fields.
   * @returns {Promise<void>}
   */
  const submitDecipherOptions = async (formValues) => {
    const { variant, language, filename } = formValues
    const xmlNotes = formValues["xml-notes"] || "" // Default to an empty string if not selected
    const qnrNotes = formValues["qnr-notes"] || "" // Default to an empty string if not selected
    const variableNames = formValues["variable-names"] || "numbering" // Default to an empty string if not selected

    try {
      await convertToXMLDecipher(project.id, {
        variant,
        language,
        filename,
        "xml-notes": xmlNotes,
        "qnr-notes": qnrNotes,
        "variable-names": variableNames,
      })

      message.success("Exported to Decipher XML successfully!")
      hideModal()
    } catch (error) {
      message.error("Failed to export to Decipher XML.")
      console.error("Error exporting to Decipher XML:", error)
      hideModal()
    }
  }

  /**
   * Resets the form fields and hides the modal.
   *
   * @function hideModal
   */
  const hideModal = () => {
    form.resetFields()
    setExportDecipherModalVisible(false)
  }

  /**
   * Preprocesses the notes array to remove duplicates and format it
   * as options for use in the Select component.
   *
   * @param {Array} notes - The array of notes to preprocess.
   * @returns {Array} The formatted options array for the Select component.
   */
  const preprocessNotes = (notes) => {
    // Remove duplicates using Set and map to the desired structure
    const uniqueNotes = [...new Set(notes)].map((note) => ({
      label: note,
      value: note,
    }))
    return uniqueNotes
  }

  /**
   * Extracts unique languages from an array of variants and a project object.
   * @param {Variant[]} variants - An array of variant objects.
   * @param {Object} project - A project object containing languages.
   * @returns {string[]} An array of unique languages.
   */
  const getUniqueLanguages = (variants, project) => {
    const variantLanguages = variants.flatMap((variant) => variant.languages)
    const allLanguages = [...variantLanguages, ...project.Languages]
    return [...new Set(allLanguages)]
  }

  // Default to MASTER if variants array is empty, or if only one language, default it
  const variantOptions =
    variants.length > 0
      ? variants.map((variant) => ({
          label: variant.name,
          value: variant.name,
        }))
      : [{ label: "MASTER", value: "MASTER" }]

  const defaultVariant =
    variantOptions.length === 1 ? variantOptions[0].value : null

  const languageOptions = getUniqueLanguages(variants, project).map(
    (language) => ({
      label: language,
      value: language,
    })
  )

  const defaultLanguage =
    languageOptions.length === 1 ? languageOptions[0].value : null

  // Get notes and preprocess them
  const notes = getQuestionnaireNotes()
  const processedNotes = preprocessNotes(notes)

  return (
    <Form
      form={form}
      name="export_to_decipher_options"
      onFinish={submitDecipherOptions}
      style={{ textAlign: "left" }}
      layout="vertical"
      initialValues={{
        variant: defaultVariant,
        language: defaultLanguage,
      }}
    >
      <Form.Item
        style={{ marginBottom: "1em" }}
        label="Please enter a filename"
        name="filename"
        validateTrigger="onSubmit"
        rules={[
          {
            required: true,
            message: "Please enter a filename",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: "1em" }}
        label="Variant"
        name="variant"
        validateTrigger="onSubmit"
        rules={[
          {
            required: true,
            message: "Please select a variant",
          },
        ]}
      >
        <Select placeholder="Select Variant" options={variantOptions} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: "1em" }}
        label="Languages"
        name="language"
        validateTrigger="onSubmit"
        rules={[
          {
            required: true,
            message: "Please select a language",
          },
        ]}
      >
        <Select placeholder="Select Language" options={languageOptions} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: "1em" }}
        label="Embed Notes in the XML file"
        name="xml-notes"
        validateTrigger="onSubmit"
      >
        <Select options={processedNotes} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: "1em" }}
        label="For testing, show notes in questionnaire"
        name="qnr-notes"
        validateTrigger="onSubmit"
      >
        <Select options={processedNotes} />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: "1em" }}
        label="Use as variable names"
        name="variable-names"
        validateTrigger="onSubmit"
      >
        <Select
          defaultValue="numbering"
          options={[
            { value: "numbering", label: "Numbered automatically" },
            { value: "labels", label: "Generate from questionnaire labels" },
          ]}
        />
      </Form.Item>

      <div className="flex flex-row mt-5">
        <div className="ml-auto mr-2 space-x-2">
          <DataQButton
            type="primary"
            htmlType="submit"
            isLoading={isConvertingToXMLDecipher}
          >
            Export
          </DataQButton>
          <DataQButton onClick={hideModal} type="secondary">
            Cancel
          </DataQButton>
        </div>
      </div>
    </Form>
  )
}

export default ExportToDecipherOptionsForm
