import React, { useEffect, useState, useMemo, useCallback } from "react"
import { useEditor } from "@tiptap/react"
import Split from "react-split"

import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Paragraph from "@tiptap/extension-paragraph"
import History from "@tiptap/extension-history"
import Placeholder from "@tiptap/extension-placeholder"
import { CommandSuggestion } from "components/Home/DataReporting/Extensions/CommandSuggestionExtension"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import Console from "components/Home/DataReporting/Console.component"
import QueryStatus from "components/Home/DataReporting/QueryStatus.component"
import QueryConsoleActions from "components/Home/DataReporting/QueryConsoleActions.component"
import DataTree from "components/Home/DataReporting/DataTree.component"
import SavedQueries from "components/Home/DataReporting/SavedQueries/SavedQueries.component"
import InvalidData from "components/Home/DataReporting/InvalidData.component"
import { Loader } from "components/UI/Loader/Loader"
import { SuccessNotification } from "components/UI/Notifications/NotificationTemplate.component"

import { useEngine } from "hooks/useEngine"
import { useQueries } from "hooks/useQueries"
import { useDataImport } from "hooks/useDataImport"
import { useAsync } from "hooks/useAsync"
import handleSequenceError from "utils/handleSequenceError"

/**
 * Container component for the Query Console.
 * This component manages the query console interface, where users can write, run, and save scripts.
 * It includes a split view layout with a data tree, query editor, and output display.
 *
 * Features:
 * - Split pane layout with persistent sizing
 * - Query editing and execution
 * - Query saving and loading
 * - Real-time script execution status
 * - Asynchronous query processing
 * - Data tree visualization
 *
 * @component
 * @example
 * return (
 *   <QueryConsoleContainer />
 * )
 *
 * @returns {JSX.Element} The rendered query console container component.
 */
const QueryConsoleContainer = ({ activeKey }) => {
  const project = useRecoilValue(projectAtom)
  const {
    initialiseData,
    isInitialisingData,
    RunEngineQuery,
    isRunningEngineQuery,
  } = useEngine()
  const { getDataFilesUploaded, isGettingDataFilesUploaded } = useDataImport()
  const { getProjectQueries, isLoadingQueries, saveQuery } = useQueries()
  const { pollingAsync, getAsyncResult } = useAsync()

  // Core state
  const [result, setResult] = useState({})
  const [isRunningScript, setIsRunningScript] = useState(false)
  const [isCodebookLoading, setIsCodebookLoading] = useState(false)
  const [viewSavedQueries, setViewSavedQueries] = useState(false)
  const [dataUploaded, setDataUploaded] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [selectedQuery, setSelectedQuery] = useState("")
  const [codebook, setCodebook] = useState([])
  const [queryInstance, setQueryInstance] = useState("")
  const [projectQueries, setProjectQueries] = useState([])
  const [contentChanged, setContentChanged] = useState(false)

  // Split pane state
  const [horizontalSplitSizes, setHorizontalSplitSizes] = useState([25, 75])
  const [consoleSplitSizes, setConsoleSplitSizes] = useState([50, 50])

  // Initialize editor
  const editor = useEditor({
    extensions: [
      Document,
      Text,
      Paragraph,
      History,
      CommandSuggestion,
      Placeholder.configure({
        placeholder:
          "Start typing your query, or type / to see available commands.",
      }),
    ],
    content: "",
    editorProps: {
      attributes: {
        class: "console-font",
      },
    },
  })

  // Derived state
  const pageLoading =
    !editor ||
    isGettingDataFilesUploaded ||
    isInitialisingData ||
    isLoadingQueries ||
    isDataLoading
  const scriptInProgress = isRunningEngineQuery && isRunningScript
  const asyncScriptInProgress = !isRunningEngineQuery && isRunningScript

  /**
   * Fetches initial data and sets up the workspace.
   * This includes loading data files and initializing the codebook.
   */
  useEffect(() => {
    getInitialData()
    fetchQueries()
  }, [])

  /**
   * Monitors editor content changes and updates state accordingly.
   */
  useEffect(() => {
    if (editor) {
      const updateContentChanged = () => {
        setContentChanged(true)
      }

      editor.on("update", updateContentChanged)
      return () => {
        editor.off("update", updateContentChanged)
      }
    }
  }, [editor])

  /**
   * Fetches and initializes the data required for the query console.
   * @async
   */
  const getInitialData = async () => {
    try {
      const fetchRes = await getDataFilesUploaded(
        project.id,
        project.Created_by,
        "json",
        "size"
      )
      if (fetchRes.data) {
        const initialiseRes = await initialiseData(project.id)
        if (
          handleSequenceError(initialiseRes.data, "Error Initializing Data!")
        ) {
          setQueryInstance(initialiseRes.data)
          setIsCodebookLoading(true)
          const codebookRes = await RunEngineQuery(
            initialiseRes.data,
            "CODEBOOK",
            false
          )
          if (
            handleSequenceError(codebookRes.data, "Error Creating Codebook!")
          ) {
            setCodebook(codebookRes.data.result.output[0].content.value)
            setDataUploaded(true)
          }
          setIsCodebookLoading(false)
        }
      }
    } catch (fetchError) {
      console.error("Error fetching data:", fetchError)
    } finally {
      setIsDataLoading(false)
    }
  }

  /**
   * Regenerates the codebook by rerunning the initial query.
   *
   * @async
   * @function regenerateCodebook
   */
  const regenerateCodebook = async () => {
    try {
      setIsCodebookLoading(true)
      const codebookRes = await RunEngineQuery(queryInstance, "CODEBOOK", false)
      if (handleSequenceError(codebookRes, "Error Creating Codebook!")) {
        setCodebook(codebookRes.data.result.output[0].content.value)
      }
      setIsCodebookLoading(false)
    } catch (error) {
      console.error("Error regenerating codebook:", error)
    }
  }

  /**
   * Fetches saved queries for the current project.
   * @async
   */
  const fetchQueries = async () => {
    try {
      const queryRes = await getProjectQueries(project.id, project.Created_by)
      if (queryRes.data) {
        let queryData = queryRes.data.toString().split("\n")
        setProjectQueries(queryData)
      } else {
        setProjectQueries([])
      }
    } catch (error) {
      console.error("Error fetching queries:", error)
    }
  }

  /**
   * Executes the current script in the editor.
   * Handles both synchronous and asynchronous query execution.
   * @async
   */
  const runScript = async () => {
    try {
      setIsRunningScript(true)
      let script = editor.getText().replace(/\n\n+/g, "\n")
      const result = await RunEngineQuery(queryInstance, script, true)

      if (result.status === 202) {
        pollForAsyncResult(result.data)
      } else {
        setResult(result.data)
        setIsRunningScript(false)
      }
    } catch (error) {
      console.error("An error occurred while running the script:", error)
      setIsRunningScript(false)
    }
  }

  /**
   * Polls for the result of an asynchronous query execution.
   * @async
   * @param {string} asyncID - The ID of the async operation to poll
   */
  const pollForAsyncResult = async (asyncID) => {
    try {
      const pollResult = await pollingAsync(asyncID)
      if (pollResult.status === 200) {
        const asyncResult = await getAsyncResult(asyncID)
        React.startTransition(() => {
          setResult(asyncResult.data)
          setIsRunningScript(false)
        })
      } else {
        setTimeout(() => pollForAsyncResult(asyncID), 5000)
      }
    } catch (error) {
      console.error("Polling error:", error)
      setIsRunningScript(false)
    }
  }

  /**
   * Saves the current query to the project.
   * @async
   * @param {string} queryName - The name to save the query under
   */
  const saveProjectQuery = async (queryName) => {
    try {
      const query = editor
        .getHTML()
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "\n")
        .replace(/&lt;&lt;/g, "<<")
        .replace(/&gt;&gt;/g, ">>")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&")
        .trim()

      const saveRes = await saveQuery(
        project.id,
        project.Created_by,
        queryName,
        "overwrite",
        query
      )

      if (handleSequenceError(saveRes.data, "Error Saving Query!")) {
        React.startTransition(() => {
          setContentChanged(false)
          fetchQueries()
          SuccessNotification(
            "Query saved successfully!",
            "View Saved Queries to access your saved queries."
          )
        })
      }
    } catch (error) {
      console.error("Error saving query:", error)
    }
  }

  /**
   * Toggles between saved queries view and editor view.
   */
  const toggleSaved = useCallback(() => {
    setViewSavedQueries((prev) => !prev)
  }, [])

  // Memoized split change handler
  const handleConsoleSplitChange = useCallback((sizes) => {
    setConsoleSplitSizes(sizes)
  }, [])

  // Memoized console component
  const consoleComponent = useMemo(
    () => (
      <Console
        editor={editor}
        result={result}
        scriptInProgress={scriptInProgress}
        asyncScriptInProgress={asyncScriptInProgress}
        splitSizes={consoleSplitSizes}
        onSplitChange={handleConsoleSplitChange}
      />
    ),
    [
      editor,
      result,
      scriptInProgress,
      asyncScriptInProgress,
      consoleSplitSizes,
      handleConsoleSplitChange,
    ]
  )

  // Memoized saved queries component
  const savedQueriesComponent = useMemo(
    () => (
      <SavedQueries
        project={project}
        toggleSaved={toggleSaved}
        projectQueries={projectQueries}
        editor={editor}
        refreshQueries={fetchQueries}
        setSelectedQuery={setSelectedQuery}
        selectedQuery={selectedQuery}
        setContentChanged={setContentChanged}
      />
    ),
    [
      project,
      projectQueries,
      editor,
      selectedQuery,
      setSelectedQuery,
      setContentChanged,
      toggleSaved,
      fetchQueries,
    ]
  )

  // Memoized main split view
  const mainSplitView = useMemo(
    () => (
      <Split
        className="flex flex-grow overflow-hidden"
        direction="horizontal"
        gutterSize={10}
        sizes={horizontalSplitSizes}
        onDragEnd={setHorizontalSplitSizes}
        minSize={100}
      >
        <div className="w-1/4 border flex flex-col overflow-hidden">
          <DataTree
            editor={editor}
            codebook={codebook}
            isCodebookLoading={isCodebookLoading}
            regenerateCodebook={regenerateCodebook}
          />
        </div>
        <div className="flex-1 flex flex-col overflow-hidden">
          {viewSavedQueries ? savedQueriesComponent : consoleComponent}
        </div>
      </Split>
    ),
    [
      horizontalSplitSizes,
      editor,
      codebook,
      isCodebookLoading,
      viewSavedQueries,
      savedQueriesComponent,
      consoleComponent,
    ]
  )

  if (pageLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader fontSize={48} color="#36C3ED" />
        {!editor
          ? "Loading Editor..."
          : isGettingDataFilesUploaded
          ? "Checking Data File..."
          : isInitialisingData
          ? "Initializing Data File..."
          : "Loading"}
      </div>
    )
  }

  if (!isDataLoading && !dataUploaded) {
    return <InvalidData />
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row p-2">
        <QueryStatus
          projectQueries={projectQueries}
          selectedQuery={selectedQuery}
          editor={editor}
          setContentChanged={setContentChanged}
          setSelectedQuery={setSelectedQuery}
        />
        <div className="flex ml-auto space-x-2">
          <QueryConsoleActions
            queryInstance={queryInstance}
            viewSavedQueries={viewSavedQueries}
            toggleSaved={toggleSaved}
            runScript={runScript}
            isRunningScript={isRunningScript}
            saveProjectQuery={saveProjectQuery}
            selectedQuery={selectedQuery}
            setSelectedQuery={setSelectedQuery}
            contentChanged={contentChanged}
            activeKey={activeKey}
          />
        </div>
      </div>
      {mainSplitView}
    </div>
  )
}

export default QueryConsoleContainer
