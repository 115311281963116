import React, { memo, useCallback } from "react"
import { List } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTrash,
  faPlus,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import DataQButton from "components/UI/Buttons/DataQButton"

/**
 * @typedef {Object} QueryItem
 * @property {string} key - Unique identifier for the item
 * @property {string} title - Display title for the query
 * @property {boolean} isCreateNew - Flag indicating if this is the "Create New Query" item
 */

/**
 * Individual list item component for query display.
 * Memoized to prevent unnecessary rerenders.
 *
 * @component
 * @param {Object} props - Component props
 * @param {QueryItem} props.item - The query item to display
 * @param {string} props.selectedQuery - Currently selected query name
 * @param {Function} props.onDelete - Callback function for delete action
 * @param {Function} props.onSelect - Callback function for select action
 * @param {Function} props.onCreate - Callback function for create new action
 * @returns {JSX.Element} Rendered list item component
 */
const QueryListItem = ({
  item,
  selectedQuery,
  onDelete,
  onSelect,
  onCreate,
}) => {
  /**
   * Handles the delete action for a query item.
   * Prevents event propagation to avoid triggering select action.
   *
   * @param {React.MouseEvent} e - The click event
   */
  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation()
      onDelete(e, item.title)
    },
    [item.title, onDelete]
  )

  /**
   * Handles click events on the list item.
   * Determines whether to trigger create or select action.
   */
  const handleClick = useCallback(() => {
    if (item.isCreateNew) {
      onCreate()
    } else {
      onSelect(null, item.title)
    }
  }, [item.isCreateNew, item.title, onCreate, onSelect])

  return (
    <List.Item
      key={item.key}
      actions={
        !item.isCreateNew
          ? [
              <DataQButton
                key={item.key}
                type="primary"
                className="mr-5"
                onClick={handleDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
              </DataQButton>,
            ]
          : null
      }
      className={`cursor-pointer ${
        item.isCreateNew
          ? "bg-[#36c3ed]/5 hover:bg-[#36c3ed]/10"
          : selectedQuery === item.title
          ? "bg-[#36c3ed]/20 hover:bg-[#36c3ed]/30"
          : "hover:bg-gray-100"
      }`}
      onClick={handleClick}
    >
      <List.Item.Meta
        className="p-3 px-5"
        title={
          item.isCreateNew ? (
            <div className="flex items-center font-semibold text-[#36c3ed] mt-1">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {item.title}
            </div>
          ) : (
            <div className="flex items-center">
              {selectedQuery === item.title && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-2 text-[#36c3ed]"
                />
              )}
              <span
                className={selectedQuery === item.title ? "text-[#36c3ed]" : ""}
              >
                {item.title}
              </span>
            </div>
          )
        }
      />
    </List.Item>
  )
}

export default memo(QueryListItem)
