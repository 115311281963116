import React, { useState, useCallback } from "react"
import { Tabs } from "antd"

import GeneralInformationTab from "components/Home/ProjectDetails/GeneralInformationTab.component"
import QuestionnaireEditorContainer from "./QuestionnaireEditor.container"
import QueryConsoleContainer from "./QueryConsole.container"
import DocumentsContainer from "./Documents.container"
import DataImportContainer from "./DataImport.container"

/**
 * ProjectDetailsTabs component - Displays tabbed navigation for different sections of project details.
 *
 * This component leverages Ant Design's Tabs component to offer a tabbed interface, facilitating
 * the display of various sections of a project's details. Currently, it includes tabs for
 * "General Information" and "Questionnaire". Each tab is responsible for rendering a specific
 * component related to the project's details. The "General Information" tab renders the
 * GeneralInformationTab component, passing down the `refreshProject` function and the `project`
 * object as props to allow for dynamic updates. The structure is designed to be easily extendable
 * with additional tabs as needed.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.refreshProject - Function to refresh the project details.
 * @returns {JSX.Element} The ProjectDetailsTabs component, rendering the tabbed interface.
 */
const ProjectDetailsTabs = ({ refreshProject }) => {
  const [activeKey, setActiveKey] = useState("1")
  const [reportingTabKey, setReportingTabKey] = useState(0)

  const items = [
    {
      key: "1",
      label: "General Information",
      children: <GeneralInformationTab refreshProject={refreshProject} />,
    },
    {
      key: "2",
      label: "Questionnaire",
      children: <QuestionnaireEditorContainer />,
    },
    {
      key: "3",
      label: "Documents",
      children: <DocumentsContainer />,
    },
    {
      key: "4",
      label: "Data Import",
      children: <DataImportContainer />,
    },
    {
      key: "5",
      label: "Data Reporting",
      children: (
        <QueryConsoleContainer
          key={`reporting-${reportingTabKey}-${activeKey}`} // Key to force rerender
          activeKey={activeKey}
        />
      ),
    },
  ]

  // Use useCallback to ensure stable reference
  const handleTabChange = useCallback((key) => {
    setActiveKey(key)
    if (key === "5") {
      // Delay the reportingTabKey update to ensure activeKey is set first
      setTimeout(() => {
        setReportingTabKey((prev) => prev + 1)
      }, 0)
    }
  }, [])

  return (
    <Tabs
      size="large"
      type="card"
      items={items}
      activeKey={activeKey}
      onChange={handleTabChange}
      className="project-details-tabs bg-white rounded-2xl h-full"
    />
  )
}

export default ProjectDetailsTabs
